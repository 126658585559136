import "./Home.css";

const Home = () => {
  return (
    <div className="home">
      <img className="home-child" alt="" src="/group-6.svg" />
      <img className="home-item" alt="" src="/vector-11.svg" />
      <div className="image-parent">
        <b className="image">IMAGE</b>
        <div className="assist">ASSIST</div>
        <img className="x-icon" alt="" src="/x.svg" />
      </div>
      <div className="home-parent">
        <div className="home1">Home</div>
        <div className="why-us">Why Us</div>
        <div className="xl-premium">XL Premium</div>
        <div className="account">Account</div>
      </div>
      <div className="vector-parent">
        <img className="group-child" alt="" src="/vector-2.svg" />
        <div className="growing-business-with">
          Growing business with a professional touch.
        </div>
      </div>
      <div className="lorem-ipsum-dolor">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam,
        purus sit amet luctus venenatis, lectus magna fringilla urna.
      </div>
      <img className="home-inner" alt="" src="/group-13@2x.png" />
      <div className="ellipse-parent">
        <img className="group-item" alt="" src="/ellipse-1.svg" />
        <img
          className="hicon-bold-play-circle"
          alt=""
          src="/hicon--bold--play-circle.svg"
        />
        <div className="group-parent">
          <div className="p-parent">
            <div className="p">P</div>
            <div className="l">L</div>
            <div className="a">A</div>
            <div className="y">Y</div>
            <div className="v">V</div>
            <div className="i">I</div>
            <div className="d">D</div>
            <div className="e">E</div>
            <div className="o">O</div>
          </div>
          <img className="group-inner" alt="" src="/ellipse-3.svg" />
        </div>
      </div>
      <img className="ellipse-icon" alt="" src="/ellipse-6.svg" />
      <img className="group-icon" alt="" src="/group-94.svg" />
      <div className="advertise-ment">Advertise-ment</div>
      <div className="div">3</div>
      <img className="adwords-2-1" alt="" src="/adwords-2-1@2x.png" />
      <img className="home-child1" alt="" src="/group-94.svg" />
      <div className="div1">2</div>
      <div className="product-editing">Product Editing</div>
      <div className="personali-zed-offers">Personali-zed Offers</div>
      <img className="home-child2" alt="" src="/group-94.svg" />
      <div className="div2">4</div>
      <img className="home-child3" alt="" src="/group-94.svg" />
      <div className="div3">1</div>
      <div className="brand-creation">Brand Creation</div>
      <img className="discount-2-1" alt="" src="/discount-2-1@2x.png" />
      <img className="brand-image-3-1" alt="" src="/brandimage-3-1@2x.png" />
      <img className="rgb-1-icon" alt="" src="/rgb-1@2x.png" />
      <div className="group-container">
        <div className="unsplashbjnxplgnji0-parent">
          <img
            className="unsplashbjnxplgnji0-icon"
            alt=""
            src="/unsplashbjnxplgnji0@2x.png"
          />
          <div className="rectangle-div" />
        </div>
        <div className="line-div" />
        <div className="group-div">
          <img className="group-child1" alt="" src="/group-137.svg" />
          <img className="line-icon" alt="" src="/line-15.svg" />
          <div className="step-01">STEP 01</div>
          <div className="rectangle-parent">
            <div className="group-child2" />
            <div className="you-give-us">You give us the idea</div>
          </div>
        </div>
        <div className="group-parent1">
          <img className="group-child3" alt="" src="/group-137.svg" />
          <img className="group-child4" alt="" src="/line-151.svg" />
          <div className="step-03-parent">
            <div className="step-03">STEP 03</div>
            <div className="rectangle-group">
              <div className="group-child5" />
              <div className="your-order-is">
                Your order is finished – you can accept it or ask for a
                correction, quality is our top priority.
              </div>
            </div>
          </div>
        </div>
        <div className="group-parent2">
          <img className="group-child6" alt="" src="/group-137.svg" />
          <img className="group-child7" alt="" src="/line-152.svg" />
          <div className="step-02">STEP 02</div>
          <div className="rectangle-container">
            <div className="group-child8" />
            <div className="we-take-it">
              We take it to the best in the industry
            </div>
          </div>
        </div>
        <div className="group-parent3">
          <img className="group-child6" alt="" src="/group-137.svg" />
          <img className="group-child7" alt="" src="/line-152.svg" />
          <div className="step-04">STEP 04</div>
          <div className="rectangle-parent1">
            <div className="group-child11" />
            <div className="with-our-support">
              With our support, your business just became more efficient!
            </div>
          </div>
        </div>
      </div>
      <div className="lorem-ipsum-dolor-container">
        <p className="lorem-ipsum-dolor-sit-amet-co">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mattis eu, in
          nunc sit. Sed euismod mi purus quam aliquam tortor. Justo feugiat leo
          nulla adipiscing nec interdum tristique. Mattis aliquam phasellus ac
          sit lorem amet euismod quis sed. Nisl sit hendrerit diam magnis
          nascetur viverra nunc. Nascetur bibendum egestas montes, pellentesque.
        </p>
        <p className="vivamus-urna-fermentum-massa-e">
          <b>Vivamus urna fermentum massa eget consectetur ipsum et gravida.</b>
        </p>
      </div>
      <div className="lorem-ipsum-dolor-container1">
        <p className="lorem-ipsum-dolor-sit-amet-co">
          <span className="lorem-ipsum-dolor2">{`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mattis eu, in nunc sit. Sed euismod mi purus quam aliquam tortor. `}</span>
          <i className="justo-feugiat">Justo feugiat</i>
          <span>
            {" "}
            leo nulla adipiscing nec interdum tristique. Mattis aliquam
            phasellus ac sit lorem amet euismod quis sed. Nisl sit hendrerit
            diam magnis nascetur viverra nunc. Nascetur bibendum egestas montes,
            pellentesque.
          </span>
        </p>
        <p className="vivamus-urna-fermentum-massa-e">
          Vivamus urna fermentum massa eget consectetur ipsum et gravida.
        </p>
      </div>
      <div className="home-child4" />
      <div className="rectangle-parent2">
        <div className="group-child12" />
        <div className="join-xl-parent">
          <b className="join-xl">JOIN XL</b>
          <img className="starwide-icon" alt="" src="/starwide.svg" />
          <img className="starwide-icon1" alt="" src="/starwide.svg" />
        </div>
      </div>
    </div>
  );
};

export default Home;
